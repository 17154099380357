.globalSettings__Wrapper {
  padding: 1.5rem;
  background: $gray-19;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .globalSettings__Header {
    display: flex;
    margin-bottom: 1.5rem;
    flex-direction: row !important;
    justify-content: space-between;
    &.integrations-header {
      position: fixed !important;
      z-index: 1 !important;
      background: #f9fafb !important;
      width: 100% !important;
      height: 2rem !important;
    }
    .globalSettings__Header__Left {
      display: flex;
      gap: 0.5rem;
      flex-direction: row !important;
      justify-content: start;
      width: auto;
      position: relative;

      .globalSettings__Header__Title {
        h1 {
          color: $blue-15;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.5rem;
          margin: 0;
        }
      }
    }
    .globalSettings__Header__Right {
      display: flex;
      gap: 0.5rem;
      flex-direction: row !important;
      justify-content: end;
      width: auto;

      h1 {
        color: $blue-15;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin: 0;
      }
    }
  }

  .globalSettings__Content {
    height: 100%;
  }
}
.globalSettings__Header--margin {
  margin-bottom: 2rem !important;
}
