.view-more {
  background-color: $gray-19;
  padding: 1.25rem;
  padding-top: 1.5rem;
  padding-left: 3.25rem;
  // box-shadow: 0px 15px 10px -15px rgba(100, 89, 89, 0.25);
  border-bottom: 1px solid #e5e7eb;

  .lead-company-details {
    width: 48.3%;
    padding-right: 0.9375rem;
  }

  .lead-company-social-details{
    width: 25%;
  }

  .lead-tags-details {
    width: 20%;
    padding-left: 0.9375rem;
  }

  @media only screen and (min-width: $screen-1600) {
    .lead-company-details {
      width: 49%;
      padding-right: 0.9375rem;
    }
    .lead-tags-details {
      width: 20%;
      padding-left: 0.9375rem;
    }
    .lead-company-social-details{
      width: 25%;
    }
  }
  @media only screen and (min-width: $screen-1400) and (max-width: $screen-1600) {
    .lead-company-details {
      width: 48.7%;
      padding-right: 0.9375rem;
    }
    .lead-tags-details {
      width: 20%;
      padding-left: 0.9375rem;
    }
    .lead-company-social-details{
      width: 25%;
    }
  }

  .user-description {
    margin-left: 0.375rem;
  }

  .user-graduationhat-container {
    margin: 0.75rem 0;

    .user-graduationhat {
      margin-left: 0.375rem;
    }
  }

  .user-skill {
    margin-left: 0.375rem;
  }

  .icon-size {
    color: $gray-15;
    width: 1rem;
    height: 1rem;
    margin-bottom: 0.5rem;
  }

  .graduationhat-icon {
    width: 1rem;
    height: 1rem;
    color: $gray-15;
    margin-bottom: 0.5rem;
  }

  .lead-description {
    color: $blue-15;
    margin-bottom: 0px;
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
  }

  .details {
    font-size: 0.75rem;
    font-weight: $font-weight-bold;

    .more-contact-details {
      margin-bottom: 0;
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: normal;
    }

    .user-phone-number {
      margin-top: 0.375rem;
    }

    .user-email {
      margin-top: 0.375rem;
    }

    .copy-icon {
      background-color: $white-text;
      border-radius: 50%;
      margin-left: 0.25rem;
      cursor: pointer;
      display: none;
      width: 1rem;
      height: 1rem;

      &:hover {
        color: $blue-11;
      }
    }

    .icon-color {
      color: $gray-15;
      margin-right: 0.375rem;
    }

    .m-details {
      color: $blue-15;
      font-weight: $font-weight-normal;
      display: flex;
      align-items: center;
      line-height: normal;

      &:hover {
        .copy-icon {
          display: block;
        }
      }
    }

    &.lead-tags-details {
      .more-contact-details {
        margin-bottom: 0.4rem;
      }
      .bs-tag {
        span {
          color: #22371f;
          font-weight: 400;
          display: flex;
          align-items: center;
          line-height: normal;
          font-family: 'Inter', sans-serif;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.prospect-tag-popover{
  .popover-body{
    .bs-popover-content{
      span{
        color: #22371f;
          font-weight: 400;
          display: flex;
          align-items: center;
          line-height: normal;
          font-family: 'Inter', sans-serif;
          font-size: 0.75rem;
      }
    }
  }
}


