.home-container {
  height: 100vh;
  float: right;
  width: 100%;
  transition: none;
  left: 0;

  .static-banner {
    position: fixed;
    top: 0;
    left: 14.375rem;
    width: 100%;
    z-index: 2;
    height: 1.5rem;
    margin-bottom: 1rem;
    padding-top: 0px;
    padding-bottom: 0.125rem;
    background: $red-14;
  }

  .alert-message-text {
    margin-left: 0.5rem;
    color: $red-12;
    font-family: Inter;
    font-style: normal;
    font-weight: $font-weight-medium;
    font-size: 14px;
    line-height: 16px;
  }

  .upgrade-link {
    margin-left: 0.5rem;
    color: $red-12;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
  }
  .alert-triangle {
    display: flex;
    padding-left: 0.4rem;
  }
}

.home-container.show-alert {
  .page-header {
    position: relative;
    top: 15px;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  margin-right: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $gray-13;
}

.nav-item-container {
  height: 90%;
  .nav-item-feedback {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .pop-over {
    &__button {
      margin-top: 1rem;
    }
  }
}

.watch-video-modal {
  .modal-dialog {
    width: 60%;
    max-width: 42.5rem;
    .modal-content {
      height: 37.625rem;
      max-height: 37.625rem;
      border-radius: 1rem !important;
      overflow: hidden;
    }
    .bs-modal-body {
      max-height: 100% !important;
      padding: 0px;
    }
    .watch-video-modal-text-container {
      padding: 2.5rem 5.375rem;
    }
    .watch-video-modal-body {
      .watch-video-modal-text {
        font-family: $font-family-sans-serif;
        display: flex;
        align-items: center;
        color: $gray-12;
        line-height: 1.25rem;
        font-variant-numeric: stacked-fractions;
        font-feature-settings: 'hlig' on, 'dlig' on;
      }
    }
  }
}
.video-modal {
  .modal-dialog {
    max-width: 42.5rem;
    max-height: 24.1875rem;
    .modal-content {
      max-height: 37.625rem;
      border-radius: 1rem !important;
      overflow: hidden;
    }
    .bs-modal-body {
      max-height: 100% !important;
      padding: 0px;
    }
    .watch-video-modal-text-container {
      padding: 2.5rem 5.375rem;
    }
  }
}

.email-verify-alert {
  margin: 0;
  padding: 0.5rem;

  min-height: 2.25rem;

  background: #fffbeb;
  border-bottom: 1px solid #fde68a !important;
  box-sizing: border-box;

  justify-content: center !important;
  align-items: center;

  .alert-body {
    justify-content: center;
    align-items: center;

    .content-div {
      text-align: center;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #92400e;

      span span {
        text-decoration: underline;
        text-underline-offset: 0.125rem;
      }
    }
  }
}
