.get-contact-info-modal {
  .modal-content {
    .modal-content-container {
      .bs-modal-header {
        border: none;

        .modal-title {
          svg {
            margin-right: 0.625rem;
          }
        }
      }

      .bs-modal-body {
        padding: 0px !important;
        padding-bottom: 24px !important;
        margin: 0 24px 1.25rem 24px;
        border-bottom: 1px solid $gray-16 !important;
        height: 16.5rem;

        div {
          &:first-child {
            margin-top: 0 !important;
          }
        }

        .second-line-get-contact-modal {
          margin-top: 0.813rem;
          color: $blue-15;
        }
      }

      .bs-modal-footer {
        border-top: none !important;
        max-height: unset;
        min-height: unset;
        padding: 0 1.5rem 1.25rem 1.5rem;

        .bs-modal-footer-action-buttons {
          margin: 0;

          .btn-ghost-outlined {
            min-width: 6.25rem;
            border-color: $gray-13 !important;
            color: $gray-15 !important;
            text-transform: capitalize;
            font-weight: 500;
            background-color: $gray-13 !important;
            &:hover {
              border-color: $blue-11 !important;
              color: $blue-11 !important;
              background-color: transparent !important;
            }
          }

          .btn-solid-primary {
            min-width: 6.25rem;
            margin-left: 1rem !important;
            background-color: $blue-11 !important;
            color: $white !important;
            font-weight: 500;
            &:hover {
              background-color: $blue-20 !important;
              border-color: transparent !important;
            }
          }
        }
      }
    }
  }
}
