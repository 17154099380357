.individual-leads {
  position: relative;

  &.leads-last-child {
    margin-bottom: 1.25rem;
  }

  &:hover {
    background-color: $gray-19;

    .view-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.leads-view-more {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

    .lead-data {
      padding-bottom: 0;
    }
  }

  .lead-data {
    border-bottom: 1px solid $gray-13;
    padding: 1rem 1.5rem;

    &.individual-leads-view-more {
      border-bottom: none;
      background-color: $gray-19;
    }

    .clickable{
      cursor: pointer;
    }

    .lead-user-details {
      width: 75%;

      .lead-user-social-container {
        flex: 40%;
      }

      .lead-company-name-container {
        flex: 27%;
        padding: 0 0.938rem;
      }

      .lead-phone-email-container {
        flex: 33%;
      }

      .lead-part {
        display: flex;
        width: 100%;

        .il-button {
          font-size: 0.875rem;
          font-weight: 500;
        }

        .shd-button {
          padding: 0.374rem 0.75rem;
        }

        .get-contact-info {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .send-btn {
          width: 2rem;
          padding: 0.375rem 0.5625rem;
        }

        .avatar-icon-container {
          max-width: 24%;

          
          .avatar-icon {
            margin: 0 0.75rem;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .lead-info {
          display: flex;
          flex-direction: column;
          padding-top: 0.125rem;

          
          .lead-name {
            font-size: 0.875rem;
            font-weight: $font-weight-semibold;
            color: $blue-15;
          }

          .lead-designation {
            font-size: 0.75rem;
            font-weight: $font-weight-normal;
            color: $blue-15;
            padding-top: 0.375rem;
          }

          .lead-location {
            margin-top: 0.375rem;

            .map-icon {
              margin-right: 0.313rem;
              position: relative;
              right: 0.125rem;
              bottom: 0.063rem;
              color: $gray-15;
            }

            span {
              font-size: 0.75rem;
              font-weight: $font-weight-normal;
              color: $gray-15;
              line-height: 1rem;
            }
          }

          .social-media-icon {
            position: relative;
            right: 0.125rem;
            margin-top: 0.375rem;

            a {
              svg {
                color: $gray-15;
                cursor: pointer;
                margin-right: 0.125rem;
                width: 1.25rem;
              }

              &:last-child {
                svg {
                  margin-right: 0;
                }
              }

              &.disabled {
                pointer-events: none;
                cursor: default;
              }
            }

            .facebook-icon {
              &:hover {
                color: $blue-25;
              }
            }

            .linkedin-icon {
              &:hover {
                color: $blue-28;
              }
            }

            .twitter-icon {
              &:hover {
                color: $blue-27;
              }
            }

            .github-icon {
              svg {
                &:hover {
                  color: $black;
                }
              }
            }

            .quora-icon {
              svg {
                &:hover {
                  color: $red-22;
                }
              }
            }

            .stackoverflow-icon {
              svg {
                &:hover {
                  color: $orange-20;
                }
              }
            }

            .me-icon {
              svg {
                height: 1.188rem;

                &:hover {
                  color: $blue-26;
                }
              }
            }

            .angelist-icon {
              svg {
                &:hover {
                  color: $black;
                }
              }
            }
          }

          .lead-company-details {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            color: $blue-15;
            padding-top: 0.125rem;

            .lead-company-logo {
              margin-right: 0.5rem;
              border-radius: 0.125rem;
            }

            .lead-company-name {
              color: $blue-15;
              font-size: 0.75rem;
              font-weight: $font-weight-semibold;
            }

            &.company-details-container {
              .icon {
                width: auto;
              }

              .lead-company-name {
                margin-left: 0.375rem;
              }
            }

            .icon {
              width: 1.375rem;

              .icon-color {
                color: $gray-15;
                width: 1rem;
                height: 1rem;
                margin-right: 0.375rem;
              }
            }

            &.lead-company-skill {
              padding-top: 0.375rem;

              .lead-company-name {
                font-weight: $font-weight-normal;
              }
            }

            &.lead-company-user-total {
              padding-top: 0.375rem;

              .lead-company-name {
                font-weight: $font-weight-normal;
              }
            }

            &.lead-company-map {
              padding-top: 0.375rem;

              .lead-company-name {
                font-weight: $font-weight-normal;
              }
            }

            &.lead-company-user-number {
              padding-top: 0;
              padding-right: 0;

              .icon {
                .icon-color {
                  margin-right: 0.375rem;
                }
              }

              .user-number {
                font-weight: $font-weight-normal;
                font-size: 0.75rem;
                color: $blue-15;
                display: flex;
                align-items: start;

                .copy-icon {
                  background-color: $white-text;
                  border-radius: 50%;
                  margin-left: 0.25rem;
                  cursor: pointer;
                  display: none;
                  width: 1rem;
                  color: $gray-15;
                  height: 1rem;

                  &:hover {
                    color: $blue-11;
                  }
                }

                &:hover {
                  .copy-icon {
                    display: block;
                  }
                }
              }
            }

            &.lead-credit-refunded {
              color: $gray-800;
              background: $gray-100;
              width: 106px;
              border-radius: 1rem;
              margin-top: 0.6rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              height: 18px;
              padding: 0px 8px 0px 8px;
              gap: 4px;
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
            }

            &.lead-company-email {
              margin-top: 0.375rem;
              padding-top: 0;
              padding-right: 0;

              .icon {
                .icon-color {
                  margin-right: 0.375rem;
                }
              }

              .user-email {
                font-weight: $font-weight-normal;
                color: $blue-15;
                font-size: 0.75rem;
                display: flex;
                align-items: start;

                .copy-icon {
                  background-color: $white-text;
                  border-radius: 50%;
                  margin-left: 0.25rem;
                  cursor: pointer;
                  display: none;
                  color: $gray-15;
                  width: 1rem;
                  height: 1rem;

                  &:hover {
                    color: $blue-11;
                  }
                }

                &:hover {
                  .copy-icon {
                    display: block;
                  }
                }
              }

              &:first-child {
                margin-top: 0;
              }
            }

            .more-account {
              display: flex;
              align-items: center;
              margin-left: 0.25rem;
              min-width: 4rem;
              height: 1.25rem;
              padding: 0 0.5rem;
              border-radius: 0.25rem;
              font-size: 0.75rem;
              color: $gray-12;
              background: $gray-13;
              font-weight: $font-weight-medium;
              cursor: pointer;

              &:hover {
                background-color: $blue-12;
                color: $blue-11;
              }
            }
          }
        }
      }
    }
  }

  .view-button {
    display: none;
    color: $blue-11;
    z-index: 1;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    min-width: 6.3125rem;
    height: 1.5rem;
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
    border: 1px solid $gray-23;
    border-radius: 0.25rem;
    text-align: center;
    position: absolute;
    bottom: -0.6875rem;
    right: 45%;
    background-color: $white;
    padding: 0.125rem 0.5rem;
    cursor: pointer;
    padding-right: 0.375rem;

    &:hover {
      background: $blue-17;
      color: $blue-11;
      border: 1px solid $blue-12;
    }

    svg {
      margin-left: 0.5313rem;
      margin-top: 0.0625rem;
    }
  }
}

.prospect-saved-btn {
  opacity: 1 !important;
  font-weight: $font-weight-medium !important;
  color: $gray-15 !important;
  border: none !important;
  background-color: $gray-16 !important;

  &:disabled {
    cursor: default;
  }
}

.prospect-btn {
  white-space: nowrap;
}

.lead-pagination {
  .pagination-select-count {
    span {
      font-size: 0.875rem;
      font-weight: $font-weight-normal;
    }

    .bs-select-box {
      border: 1px solid $gray-13;
      border-radius: 0.25rem;
      background-color: $white;
    }
  }
}

// checkbox tooltip
.checkbox-tooltip {
  .tooltip-inner {
    max-width: 15.313rem;
  }
}

// credit refunded tooltip
.credit-refunded-tooltip {
  .tooltip-inner {
    max-width: 14.313rem;
    max-height: 4.567rem;
  }
}

.checkbox-custome {
  .bs-checkbox-inner {
    border-radius: 4px !important;
  }
}

// Line Height Class
.lh-0 {
  line-height: 0;
}

.lh-16 {
  line-height: 1rem;
}