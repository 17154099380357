.table-list {
  position: relative;
  height: calc(100vh - 10rem);
  overflow-y: auto;
  overflow-x: auto;

  .bs-table-small .bs-table-tbody td {
    &.selection-cell {
      width: 1.875rem;
    }
  }

  .bs-table-small .bs-table-thead > tr > th {
    font-weight: $font-weight-semibold;
    &.selection-cell-header {
      width: 1.875rem;
    }
    &.th-center {
      text-align: center;
    }
  }
  .bs-table-tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
      &:first-child {
        td {
          border-bottom: 0.0625rem solid $gray-4;
        }
      }
    }
  }
  .table-switch {
    label {
      margin-bottom: 0;
    }
  }
  .grid-action {
    .btn-solid {
      padding: 0.2rem 0.1rem;
      i {
        font-size: 1.2rem;
      }
    }
  }
  .grid-flex {
    display: flex;
    justify-content: space-evenly;
    :hover {
      cursor: pointer;
    }
    align-items: center;
  }

  .grid-center {
    display: flex;
    justify-content: center;
    :hover {
      cursor: pointer;
    }
    align-items: center;
    .action-icon-generic {
      width: 34px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6b7280;
      font-weight: 500;
      &:hover {
        background: $blue-13;
        color: #1d4ed8;
      }
    }
  }
  .react-bootstrap-table {
    .table-organism {
      .table-organism-header > tr > th {
        background-color: transparent;
        border: none;
        white-space: nowrap;
        border-top: 0.0625rem solid $gray-14;
        border-bottom: 0.0625rem solid $gray-14;
        &:first-of-type {
          border-top-left-radius: 0.25rem;
          padding-left: 1.875rem;
          border-left: 0.0625rem solid $gray-14;
        }
        &:last-of-type {
          border-top-right-radius: 0.25rem;
          padding-right: 1.875rem;
          border-right: 0.0625rem solid $gray-14;
        }
      }
      .table-organism-header {
        border: none;
        position: sticky;
        top: 0%;
        z-index: 2;
        background: $gray-19;
        box-shadow: 0px 4px 4px rgba(229, 231, 235, 0.25);

        th {
          font-weight: $font-weight-semibold;
          color: $gray-12;
          font-size: 0.75rem;
        }
        .sortable-custom div {
          display: flex;
          align-items: center;
          &:hover {
            span {
              background: transparent;
            }
          }
          span {
            padding: 0;
            margin-right: 0.375rem;
          }
        }
        div {
          span {
            font-weight: $font-weight-semibold;
            color: $gray-12;
          }
        }
        .sort-button {
          margin-left: 0.625rem;
        }
      }
      .table-organism-body {
        tr {
          transition: all 0.2s ease;
          &:hover {
            background: $gray-19;
            box-shadow: 0.125rem 0px 0.25rem rgba(31, 41, 55, 0.16);
            .toggler-column,
            .fixed-td {
              transition: all 0.2s ease;
              background: $gray-19;
            }
          }

          .toggler-column {
            background: $white;
          }

          &.expand-row {
            background: $gray-19;
            .sequence-name {
              padding-left: 1.5rem;
              span {
                font-weight: normal !important;
              }
            }
            td,
            .sequence-owner,
            .unsubscribe,
            .toggler-column,
            .fixed-td {
              background: $gray-19;
            }
          }

          .fixed-td {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: white;
            transition: 0.2s ease;
            &.team-sequence-name {
              &.sequence-name {
                left: 2.7919rem;
              }
            }
          }
        }
        td {
          vertical-align: middle !important;
          border: none;
          border-bottom: 0.0625rem solid $gray-13;
          &:first-of-type {
            padding-left: 1.875rem;
          }
          &:last-of-type {
            padding-right: 1.875rem;
          }
        }
        .text-container {
          width: 10.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }
        &.no-data {
          tr {
            &:hover {
              cursor: default;
              box-shadow: none;
              background: transparent;
            }
          }
          td {
            border-bottom: none;
          }
        }
      }
      .sortable-custom {
        div span {
          border-radius: 0.1875rem;
          padding: 0.2rem 0.5rem;
          i {
            font-weight: $font-weight-semibold;
            color: $gray-700;
          }
        }
        div:hover {
          text-decoration: inherit;
          transition-duration: 0s, 0.15s;
          color: rgb(66, 82, 110) !important;
          span {
            background: $gray-5;
            visibility: visible !important;
          }
        }
      }
    }
    .table {
      margin-bottom: 0;
    }
  }

  .empty-list {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &__inner-vertical {
      position: static;
      top: auto;
      left: auto;
      transform: none;
    }
    &__content-vertical {
      height: auto;
      margin-top: 1.875rem;
    }
  }
}

.table-list::-webkit-scrollbar {
  width: 0.4375rem;
  margin-right: 0.25rem;
}

.table-list::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: $gray-13;
}

.pagination-container-new {
  position: sticky !important;
  bottom: 0px !important;
  background: $gray-1 !important;
  height: 2.5rem !important;
  padding-right: 1.125rem !important;
  .pagination {
    margin-right: 0px !important;
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  border-top: 0.0625rem solid $gray-23;
  z-index: 10;
  background: $gray-19;
  height: 4rem;
  .page-record-count {
    display: flex;
    align-items: center;
    label {
      color: $gray-9;
      margin: 0;
    }
    .dropdown-toggle-custom {
      border: 0.0625rem solid $gray-5;
      padding: 0.314rem 0.5rem;
    }
    .dropdown-menu-custom {
      width: auto;
      min-width: 5rem;
    }
  }
  .pagination-count {
    .pagination {
      margin: 0;
    }
  }

  padding: 1.125rem 1.5rem;
  background: $gray-19;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;

  .pagination-row {
    align-items: center;
    & > span {
      color: $blue-15;
    }

    .divider {
      width: 1px;
      height: 1.25rem;
      background: $blue-14;
      margin: 0px 1.25rem;
    }
  }
  .pagination-select-count {
    min-width: 15rem;
    .bs-select-box {
      min-width: 4.625rem;
      max-width: 6rem;
      width: 4.625rem;
      height: 1.5rem;
    }

    .total-pagination-count {
      width: 7rem;
    }
  }
  .pagination-page-input {
    .go-to-page {
      width: max-content;
    }
    .page-number-input {
      width: 3.9375rem;
      height: 1.5rem;
    }
    .input-bounce {
      outline: 0;
      animation-name: bounce;
      animation-duration: 0.5s;
      animation-delay: 0.25s;
    }

    @keyframes bounce {
      0% {
        transform: translateX(0px);
        transition-timing-function: ease-in;
      }
      37% {
        transform: translateX(5px);
        transition-timing-function: ease-out;
      }
      55% {
        transform: translateX(-5px);
        transition-timing-function: ease-in;
      }
      73% {
        transform: translateX(4px);
        transition-timing-function: ease-out;
      }
      82% {
        transform: translateX(-4px);
        transition-timing-function: ease-in;
      }
      91% {
        transform: translateX(2px);
        transition-timing-function: ease-out;
      }
      96% {
        transform: translateX(-2px);
        transition-timing-function: ease-in;
      }
      100% {
        transform: translateX(0px);
        transition-timing-function: ease-in;
      }
    }
  }
  .pagination {
    margin: 0px 1.875rem;
    .page-item {
      .page-link {
        color: $blue-15;
        padding: 0;
        font-size: 0.875rem;
        min-width: 1.4375rem;
        min-height: 1.5rem;
        border-radius: 0.25rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;

        &:hover {
          background: $blue-13;
          color: $blue-11;
          border: none;
          border-color: transparent;
        }
      }
      &.disabled {
        &:first-of-type,
        &:last-of-type {
          .page-link {
            color: $blue-15;
            opacity: 0.32;
          }
        }
      }
      &.disabled.active {
        &:first-of-type,
        &:last-of-type {
          .page-link {
            background-color: transparent;
          }
        }
      }
      &.active {
        .page-link {
          background: $blue-13;
          color: $blue-11;
          border: none;
          border-color: transparent;
        }
      }
    }
  }
}
.table-sort-tooltip {
  flex-wrap: nowrap;
  .arrow {
    display: none !important;
  }
}

.max-rows-selected {
  margin-left: -1.25rem !important;
  .arrow {
    left: 1.25rem !important;
  }
}

.columns-toggler-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 10.1875rem;
  height: 2rem;
  padding: 0.5rem 0.75rem;

  font-size: 0.75rem;
  font-weight: $font-weight-medium;
  color: $blue-15;

  background-color: $gray-1;
  border: 0.0625rem solid $gray-26;
  border-radius: 0.25rem;

  cursor: pointer;

  svg {
    color: $gray-11;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    i {
      color: $gray-12;
    }
  }
}

.columns-toggler-select {
  padding: 2px 0 0 0 !important;
  height: 2rem;
  .transfer-content {
    height: calc(100vh - 31.25rem);
    min-height: 2.5rem;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
  .transfer-footer {
    border-top: 1px solid $gray-13;
  }
  .transfer-list {
    li {
      height: 40px;
      line-height: 20px !important;
      padding: 10px 16px !important;

      .checkbox-wrapper,
      .checkbox-wrapper label {
        width: 1rem;
        height: 1rem;
        line-height: 20px;
      }

      .option-content {
        display: flex;
        align-items: center;
        height: 20px;
        line-height: 20px;
        width: calc(100% - 24px);
      }
    }
    .disabled {
      pointer-events: none;
      .bs-checkbox-wrapper.bs-checkbox-wrapper-disabled
        .bs-checkbox.bs-checkbox-disabled
        .bs-checkbox-inner {
        opacity: 0.5;
        background-color: $blue-11;
        border-width: 0px;
        &::after {
          background-color: $blue-11;
        }
      }
      .option-content {
        opacity: 0.5;
      }
    }
  }
  .sequence-name-item {
    font-size: 0.875rem;
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    line-height: 1.25rem;
    color: $blue-15;
    margin-top: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .columns-name {
    font-size: 0.875rem;
  }
}

.columns-expand {
  cursor: pointer;
}
