.update-email-header {
  .btn-ghost {
    padding: 0.1rem 0.6rem !important;
    line-height: 1.25rem !important;
    font-size: 0.75rem;
    font-weight: 400;
    min-width: 6.25rem;
    font-weight: $font-weight-medium;
    align-items: center;
  }
  .smtp-email-header {
    display: flex;
    gap: 1.25rem;
    line-height: 1rem;
  }
  p {
    margin: 0;
  }
  .email-switch {
    height: 1rem;
  }
  p {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray-15;
    gap: 1rem;
    .email-account-type {
      margin-right: 0rem;
    }
  }
  .update-email-header-left {
    .back-btn {
      margin-right: 0.625rem;
    }
    .email-info {
      h3 {
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        font-size: 1rem;
        color: $blue-15;
        margin: 0;
        margin-bottom: 0.4375rem;
      }

      .smtp-label {
        width: 2.4375rem;
        padding: 0rem 0.3125rem;
        margin-left: 0.9375rem;
        span {
          font-size: 0.625rem;
          font-weight: $font-weight-medium;
        }
      }
      .edit-email-settings-btn.btn-ghost {
        margin-left: 0.9375rem;
      }
    }
  }
  .update-email-header-right {
    display: flex;
    align-items: center;
    .connect-disconnect-label {
      margin-right: 0.625rem;
      font-weight: $font-weight-medium;
      font-size: $text-14;
      line-height: 1.25rem;
      &.connected {
        color: $green-16;
      }
      &.disconnected {
        color: $blue-15;
      }
    }
  }
}

.update-email-tabs {
  margin: 0rem 0rem 0rem 1.7rem;
  &.bulk-update-email-tabs {
    margin: 0rem !important;
  }
  p {
    margin: 0;
  }
  .bs-tabs-nav {
    margin-bottom: 1.5rem !important;
    display: flex;
    align-items: start !important;
    &::before {
      border-bottom: 0rem solid #e8e8e8 !important;
    }
    .bs-tabs-nav-wrap {
      height: 1.875rem !important;
      align-self: auto !important;
      top: 0;
    }
  }
  .bs-tabs-content-holder {
    max-height: calc(100vh - 221px);
    overflow-y: auto;
    padding-bottom: 1.5rem;
  }
  .sending-settings-wrapper {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 1.875rem 1.5rem;

    .emails-limit-wrapper {
      display: flex;
      align-items: center;
      .emails-limit-info {
        margin-right: 3.75rem;
        h3 {
          font-weight: $font-weight-semibold;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: $blue-15;
          margin-bottom: 0.3125rem;
        }
        p {
          font-size: 0.75rem;
          line-height: 0.9375rem;
          color: $gray-15;
        }
      }
    }
    .number-input {
      .bs-input-number-container {
        .bs-input-number-input {
          width: 5.625rem;
          height: 2.25rem;
          background: $gray-19;
          box-sizing: border-box;
          border-radius: 0.25rem;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: $blue-15;
        }
        .bs-input-number-handler-wrap {
          background: $gray-16;
        }
      }
    }
    .time-interval-wrapper {
      margin-top: 1.5625rem;
      .number-input-container {
        margin-top: 1.25rem;
        .bs-input-number-container {
          .bs-input-number-input-wrap {
            width: 5.625rem;
            border-radius: 0.25rem;
            .bs-input-number-input {
              width: inherit;
              height: 2rem;
              background: $gray-19;
              border-radius: 0.25rem;
            }
          }
          .bs-input-number-handler-wrap {
            background: $gray-16;
            border-radius: 0.25rem;
          }
        }
      }
      .error-wrapper {
        border-radius: 0.25rem;
        padding: 0.9375rem 0px;
        margin-top: 0.625rem;
      }
      .time-interval-error-wrapper {
        background: $red-17;
        width: 22.25rem;
      }
      .time-interval-warning-wrapper {
        background: $yellow-11;
        width: 43.5rem;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
      }
      .disabled {
        cursor: not-allowed;
      }
    }
    .daily-rampup-wrapper {
      margin-top: 1.5rem;
      .daily-rampup-container {
        padding-left: 1.25rem;
        border-left: 0.1875rem solid $gray-16;
        border-radius: 0.25rem;
        margin-bottom: 1.875rem;
      }
      .daily-rampup-toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1.5rem;
        .daily-rampup-toggle-switch {
          width: 2rem;
          height: 1rem;
        }
        .switchToggle input:checked + label {
          background: $blue-11;
        }
        h3 {
          font-weight: $font-weight-semibold;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          margin: 0;
        }
        .info-icon {
          cursor: pointer;
        }
        .youtube-icon {
          cursor: pointer;
        }
        .daily-ramup-toggle-tooltip {
          .tooltip-inner {
            max-width: 100%;
            max-width: 13.25rem;
          }
        }
      }
      .daily-rampup {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        p {
          margin: 0;
          font-weight: $font-weight-medium;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: $blue-15;
          transition: all 0.2s ease;
          span {
            color: $gray-15;
          }
        }
        .number-input {
          margin: 0 0.9375rem;
          transition: all 0.2s ease;
          .bs-input-number-container {
            .bs-input-number {
              min-width: 0;
            }
            .bs-input-number-input {
              width: 4.6875rem;
              height: 2rem;
            }
          }
        }
        &.disabled {
          p {
            opacity: 0.35;
          }
          .number-input {
            opacity: 0.35;
          }
        }
        &:nth-of-type(2) {
          margin-bottom: 1rem;
        }
        &.rampup-result {
          p {
            font-weight: $font-weight-semibold;
            span {
              color: $orange-12;
              border-bottom: 0.125rem solid $gray-23;
              border-radius: 0.125rem;
            }
          }
        }
      }
    }
    .alert {
      margin: 0;
    }
  }

  .block-page-container {
    height: 59vh;
  }
}

.update-email-account-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .update-email-account-loading-spinner {
    border-color: $blue-11;
    border-right-color: transparent;
  }
  &.email-tab {
    height: 50vh;
  }
  &.email-tracking {
    height: 80vh;
  }
}

.youtube-video-modal {
  .modal-content {
    min-width: 38.625rem;
  }
}

.delete-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    margin-left: 0.2rem;
    padding-top: 0.4rem !important;
  }

  .bs-modal-footer-action-buttons {
    button {
      min-width: 6.25rem;
    }
  }
}

.general-settings-wrapper {
  width: 100%;

  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 4px;

  padding: 1.875rem 1.5rem;
  .general-settings-wrapper-max-col-width {
    max-width: 436px;
  }
  .general-settings-input {
    label {
      font-size: $text-12;

      &.bs-input-label-prefix {
        font-weight: $font-weight-semibold;
        line-height: 0.9375rem;
        color: $gray-15;
        margin-bottom: 0.3125rem;
      }
    }
    span.bs-input-affix-wrapper {
      background: $gray-19;
      height: 2.25rem;
      input {
        background: $gray-19;
        font-size: $text-14;
        line-height: 1.0625rem;
        color: $blue-15;
      }
    }
    .bs-select-box {
      background: $gray-19;
      height: 2.25rem;
    }
    .editor {
      height: 7.875rem;
    }
  }
}

.agency-dropdown {
  .agency-client-wrapper {
    width: 25.375rem;
    margin-top: 0px;
    margin-bottom: 0px;
    &:hover {
      border-color: $blue-5;
      border-right-width: 0.0625rem !important;
    }
    &.agency-client-show {
      border-color: $blue-5;
      border-right-width: 0.0625rem !important;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.5);
    }
  }
}

.email-health-score-factor-details-accordians {
  .custom-accordion {
    .custom-accordion-item {
      &.not-valid {
        border-color: $red-11;
      }
    }
  }
}

.disconnect-email-account-modal {
  .modal-body {
    .submit-btn.btn-solid {
      width: 5rem;
      margin-left: 0.5rem;
    }
  }
}

.update-email-account-container {
  background: $gray-19;
  height: calc(100vh - 3.875rem);
  overflow-y: hidden;
}

.email-accounts-header {
  .connected-email-accounts-badge {
    margin-top: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.125rem 0.375rem;
    border-radius: 0.25rem;
    background: $gray-13;

    &:hover {
      cursor: default;
    }
    span {
      color: $gray-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1rem;
    }
  }
}

.email-accounts-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  .search-input {
    width: 12.625rem;
    height: 2rem;

    justify-content: flex-start;
    align-items: center;
    padding: 0.3125rem 0.625rem;

    background: $white;
    margin-right: 0.25rem;
    .input-icons-left {
      width: 1rem;
      height: 1rem;

      margin: 0 !important;
      margin-right: 0.25rem !important;

      .bs-input-prefix {
        color: #d1d5db;
        margin: 0;
        font-size: 1rem;
      }
    }

    .input-icons-right {
      width: 1rem;
      height: 1rem;

      margin: 0 !important;
      margin-left: 0.25rem !important;

      .bs-input-suffix {
        color: #6b7280;
        margin: 0;
        font-size: 1rem;
        cursor: pointer;
      }
    }

    .bs-input {
      background: $white;
      color: $blue-15;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;

      &::placeholder {
        color: #d1d5db;
      }
    }
  }

  .icon-container {
    margin: 0 1.25rem;
    // position: relative;
    // display: inline-block;

    .icon {
      height: 2rem;
      width: 2rem;
      border-radius: 0.25rem;
      cursor: pointer;
      // margin: 0 auto;
      svg {
        color: $gray-15;
      }
      &:hover {
        background-color: $blue-13;
        svg {
          color: $blue-11;
        }
      }
    }
  }

  .email-account-icons {
    margin-left: 1.25rem;
    gap: 1.25rem;
    .icon {
      height: 2rem;
      width: 2rem;
      border-radius: 0.25rem;
      cursor: pointer;
      svg {
        color: $gray-15;
      }
      &:hover {
        background-color: $blue-13;
        svg {
          color: $blue-11;
        }
      }
      &.disabled {
        opacity: 0.35;
        cursor: not-allowed;
        &:hover {
          background-color: $gray-16;
          svg {
            color: $gray-15;
          }
        }
      }
    }
  }
  .agency-client-email-accounts {
    margin-left: 1.25rem;
  }
  .login-with-trulyinbox {
    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.active-sequences-popover {
  width: 13.75rem !important;
  max-height: 10.625rem;
  padding: 0.5rem 0rem !important;
  .popover-body {
    width: 100%;
  }
}

.connected-email-accounts-popover,
.active-sequences-popover {
  display: flex;

  border-radius: 0.5rem !important;
  border: 0.0625rem solid $gray-16 !important;
  background: $white !important;
  box-shadow: 0.1875rem 0.5rem 1.5rem -0.375rem rgba(15, 34, 67, 0.16) !important;

  .arrow {
    display: none;
  }

  .bs-popover-inner-content {
    padding: 0 !important;
  }

  .connected-email-accounts {
    min-width: 11.063rem;
    padding: 0.75rem;
    border: 1px solid #f3f4f6;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;

    .connect-email-accounts-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--inner {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 8.125rem;

        .active,
        .inactive {
          width: 0.5rem;
          height: 0.5rem;

          border-radius: 0.5rem;
          border: 0.0625rem solid $green-14;
          background: $green-14;
        }
        .inactive {
          border-color: $gray-11;
          background: $gray-11;
        }
      }
      p {
        color: $blue-15;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1rem;
        margin: 0;
      }
    }
  }

  .active-sequences-popover-content {
    h2 {
      display: flex;
      align-items: center;
      padding: 0.625rem 1rem;
      margin: 0;

      color: $gray-12;
      font-size: 0.875rem;
      font-weight: $font-weight-semibold;
      line-height: 1.25rem;
    }
    .active-sequences-wrapper {
      max-height: 108px;
      overflow-y: auto;
    }
    .active-sequence {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: 0.5rem 1rem;

      height: 2.25rem;

      p {
        color: $blue-11;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.email-selection-info {
  &--wrapper {
    width: 100%;
    height: 2.5rem;
    border: 0.0625rem solid $orange-18;
    background: $yellow-11;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    p {
      margin: 0;
      color: $blue-15;
      font-size: 0.75rem;
      font-weight: $font-weight-semibold;
      line-height: 1.375rem;
    }
  }
  &--separator {
    width: 0.0625rem;
    height: 1.25rem;
    background: $gray-11;
  }
  &--cta {
    color: $blue-11;
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
    line-height: 1.375rem;
    cursor: pointer;
  }
}

.table-scroll-container {
  overflow-x: auto;
  width: 100%;
  &.selected {
    .table-list {
      height: calc(100vh - 14rem);
    }
  }
  &.not-selected {
    .table-list {
      height: calc(100vh - 11.5rem);
    }
  }

  &.trial-plan {
    &.not-selected {
      .table-list {
        height: calc(100vh - 14rem);
      }
    }
    &.selected {
      .table-list {
        height: calc(100vh - 16.5rem);
      }
    }
  }
}

.email-accounts-table {
  min-width: 78.5rem;
  width: 100%;
  &.table-list {
    flex: 1;
    overflow-x: auto !important;
    min-width: 100%;
    // overflow-x: hidden !important;
  }
  .table-organism-header {
    background-color: #f3f4f6 !important;
    tr:hover {
      background-color: inherit !important;
    }
    .selection-cell-header {
      position: sticky;
      left: 0;
      z-index: 0;
      background-color: #f3f4f6 !important;
    }
  }
  .table-organism-body > tr {
    > td {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:first-of-type {
        border-left: 0.0625rem solid #e5e7eb !important;
      }
      &:last-of-type {
        border-right: 0.0625rem solid #e5e7eb !important;
        border-left: 0.0625rem solid #e5e7eb !important;

        position: sticky;
        right: 0;
        background-color: white;
        z-index: 1;
      }
    }
  }
  .table-organism-body {
    tr {
      background: $white;
    }
    .warmup-status-cell {
      div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.375rem;
        height: 1.25rem;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        cursor: default;

        .badge-dot {
          width: 0.5rem;
          height: 0.5rem;
          display: inline-block;
          border-radius: 0.25rem;
        }
        .badge-label {
          font-weight: 400;
          line-height: 1rem;
        }
      }
      .clickable {
        cursor: pointer;
      }
      .stopped {
        color: $popover-arrow-color;
        background-color: #fef2f2;
        .badge-dot {
          background: #dc2626;
        }
      }
      .running {
        color: $popover-arrow-color;
        background-color: #ecfdf5;
        .badge-dot {
          background: #059669;
        }
      }
      .paused {
        color: $popover-arrow-color;
        background-color: #f3f4f6;
        .badge-dot {
          background: #6b7280;
        }
      }
      .suspended {
        color: $popover-arrow-color;
        background-color: #fffbeb;
        .badge-dot {
          background: #d97706;
        }
      }
    }
    .deliverability-score-cell {
      font-weight: $font-weight-medium;
      color: $blue-15;
    }
    .selection-cell {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: white;
    }
    tr:hover {
      .selection-cell {
        background-color: $gray-19;
        transition: all 0.2s ease;
      }
      .status-cell {
        background-color: $gray-19;
        transition: all 0.2s ease;
      }
      .name-cell {
        background-color: $gray-19;
        transition: all 0.2s ease;
      }
      td:last-of-type {
        background-color: $gray-19;
        transition: all 0.2s ease;
      }
    }
  }
  .bs-table-content {
    margin-bottom: 0.875rem;
    .agency-client-switch-cell {
      .switchToggle {
        display: flex;
        align-items: center;
        justify-content: center;
        label {
          margin: 0;
        }
      }
    }
  }

  .pagination-container-new {
    bottom: 0 !important;
    left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: fixed !important;
    height: 2.5rem !important;
    background: #f9faf9 !important;
    width: 100%;
    .pagination-row {
      width: 100%;
      justify-content: flex-end;
      height: 1.5rem;
    }
    .pagination-select-count {
      min-width: auto;
      width: 100%;
      height: 1.5rem;
      gap: 0.5rem;
      flex: 1;
      justify-content: flex-end;
      span {
        margin: 0 !important;
      }
      .total-pagination-count {
        width: auto;
      }
    }
  }

  .email-account-switch-cell {
    .switchToggle {
      input:checked + label {
        background: $green-16;
      }
      label {
        margin-bottom: 0 !important;
        margin-top: 0.3rem;
        border-radius: 1rem;
        background: $gray-11;
      }
    }
  }

  .sender-name-cell {
    .sender-name-field {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.25rem;
      &--inner {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .fromName {
          color: $blue-15;
          font-size: 0.875rem;
          font-weight: $font-weight-semibold;
          line-height: 1.25rem;
          margin: 0;
        }
        .fromEmail {
          color: $gray-12;
          font-size: 0.75rem;
          font-weight: $font-weight-medium;
          line-height: 1.125rem;
          margin: 0;
        }
        .type {
          width: 1rem;
          height: 1rem;
        }
        .status {
          width: 1rem;
          height: 1rem;
        }
        .default-email-badge {
          display: flex;
          height: 1.125rem;
          padding: 0rem 0.5rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.25rem;
          border-radius: 6.25rem;
          background: $green-11;

          color: $blue-15;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.125rem;

          &:hover {
            cursor: default;
          }
        }
        .sender-name-field-smtp-label {
          .smtp-label-container {
            width: 2.8125rem;
            height: 1.125rem;
            border-radius: 6.25rem;
            span {
              font-size: 0.625rem;
              line-height: 1rem;
            }
          }
          transition: all 0.2s ease;
          cursor: default;
        }
        .sender-name-field-modify {
          border-radius: 0.25rem;
          border: 0.0625rem solid $gray-23;
          color: $gray-15;
          background: #fff;
          display: flex;
          padding: 0.25rem;
          justify-content: center;
          align-items: center;
          gap: 0.3516rem;
          cursor: pointer;
          opacity: 0;
          transition: opacity 0.3s ease;
          &:hover {
            background-color: $blue-17;
            color: $blue-11 !important;
            border: 0.0625rem solid $blue-19;
          }
        }
      }
    }
  }

  .bs-table-content .table-organism-body tr:hover {
    .sender-name-field-modify {
      opacity: 1;
    }
  }

  .setup-score-cell,
  .active-sequences-field {
    .shd-button--link {
      padding: 0 !important;
      min-width: 7.5rem;
    }
  }

  .active-sequences-field {
    display: flex;
    .active-sequences-label {
      color: $blue-11;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      line-height: 1.125rem;
      margin: 0;
    }
  }

  .setup-score-field {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.125rem 0.5rem;

    width: 2rem;
    height: 1.5rem;

    border: 1px solid transparent;
    border-radius: 20px;

    span {
      font-weight: $font-weight-semibold;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &.poor {
      background: $red-17;
      border-color: $red-18;
      span {
        color: $red-18;
      }
    }
    &.average {
      background: $yellow-11;
      border-color: $yellow-13;
      span {
        color: $yellow-13;
      }
    }
    &.excellent {
      background: $green-21;
      border-color: $green-22;
      span {
        color: $green-22;
      }
    }
  }

  .email-sent-field {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    width: 8.875rem;

    .email-sent-progress-bar {
      width: 4rem;
      height: 0.375rem;
      border-radius: 0.5rem;

      .progress-bar-wrapper {
        height: 100%;
      }
      &.blue-13 {
        .progress-bar-wrapper {
          background-color: $blue-13;
          border-radius: 0.5rem;
        }
      }
      &.red-19 {
        .progress-bar-wrapper {
          background-color: $red-19;
          border-radius: 0.5rem;
        }
      }

      &.red-bar {
        .progress-bar-wrapper__bar {
          background-color: $red-15 !important;
        }
      }
      &.blue-bar {
        .progress-bar-wrapper__bar {
          background-color: $blue-24 !important;
        }
      }
    }

    .email-sent-stat {
      p {
        color: $gray-11;
        font-size: 0.75rem;
        font-weight: $font-weight-medium;
        line-height: 1.125rem;
        margin: 0;
        transition: all 0.2s ease;
        span {
          color: $blue-15;
          font-size: 0.875rem;
        }
        &:hover {
          color: $blue-11;
          span {
            color: $blue-11;
          }
        }
      }
    }
  }

  .added-by-field {
    .shd-avatar {
      cursor: default;
    }
  }

  .dropdown-menu-custom {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;

    width: 9.4375rem;
    padding: 0.5rem 0rem;
    margin: 0;

    border-radius: 4px;
    border: 1px solid #e5e7eb;

    background: #fff;
    position: absolute;
    left: 0;
    transform: translateX(-100%);

    /* dropdown */
    box-shadow: 8px 16px 56px -24px rgba(156, 157, 161, 0.24),
      2px 2px 16px -3px rgba(4, 21, 76, 0.16);

    .dropdown-item-custom {
      width: 9.3125rem;
      &:hover {
        background: #eff6ff;
      }
      svg {
        color: #6b7280;
      }
      span {
        line-height: 1.25rem;
        color: #374151;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin-right: 0 !important;
      }
    }
  }
}

.email-account-client-associated-tooltip {
  .arrow {
    position: absolute !important;
    left: 0px !important;
    transform: translate(11px, 0px) !important;
  }
}

.no-search-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  h2 {
    color: $blue-15;
    text-align: center;
    font-size: 0.875rem;
    font-weight: $font-weight-semibold;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    color: $gray-15;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
  }
}

.mr-7px {
  margin-right: 0.4375rem;
}

.email-sent-stat-tooltip {
  .tooltip-inner {
    width: 10.625rem;
    height: 4.625rem;
  }
  .email-sent-stat-tooltip-content {
    display: flex;
    flex-direction: column;

    span {
      display: block;
    }
  }
}

.bulk-email-update-modal {
  overflow: hidden !important;
  .modal-dialog {
    max-width: 28.375rem !important;
    min-height: 42.813rem !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .modal-content {
      max-height: 100%;
      flex: 1;

      .modal-content-container {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }

    .modal-header {
      padding: 1.5rem !important;
      border-bottom: none;
      .modal-title {
        color: $blue-15;
        font-size: 1rem;
        font-style: normal;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
      }
    }
    .modal-body {
      padding: 0 !important;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-self: stretch;

      .bs-tabs-nav {
        margin: 0 1.5rem !important;
      }

      .bs-tabs-nav-list {
        gap: 1.5rem;
        .bs-tabs-tab {
          font-size: 0.875rem;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 1.25rem;
          color: $gray-30;
          padding: 0;
          padding-bottom: 0.875rem;
          margin: 0;
        }
        .bs-tabs-tab-active {
          color: $blue-11;
          font-weight: $font-weight-semibold;
        }
      }
      .signature-editor {
        height: 11.225rem !important;
      }
      .bs-tabs-content-holder {
        padding: 1.5rem !important;
        padding-bottom: 0.9rem !important;
      }
      .editor {
        height: 9.125rem !important;
      }

      .bs-input-label-prefix {
        margin-bottom: 0.25rem;
      }
    }
    .modal-footer {
      .btn-solid-primary {
        min-width: 6.25rem;
        height: 2rem;
        border: none;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        transition: all 0.2s ease;
      }
      .btn-ghost-primary {
        min-width: 6.25rem;
        height: 2rem;
        border: 0.0625rem solid $gray-13;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        transition: all 0.2s ease;
        color: $gray-15;
        &:focus,
        &:hover {
          color: $gray-15;
          border-color: $gray-13;
        }
      }
    }
  }

  .general-settings-wrapper {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .row {
      margin-bottom: 0 !important;
      .general-settings-input {
        margin-bottom: 0 !important;
        .bs-input-affix-wrapper {
          height: 2.25rem;
          padding: 0.5rem 0.75rem;
          box-shadow: none;
          min-width: 25.375rem;
          .bs-input {
            color: $blue-15;
            font-size: 0.875rem;
            font-weight: $font-weight-normal;
            line-height: 1.25rem;
            &::placeholder {
              color: $gray-11;
            }
          }
        }
        .bs-select-box {
          height: 2.25rem;
          padding: 0.5rem 0.75rem;
          box-shadow: none;
          min-width: 25.375rem;
        }
        .editor {
          box-shadow: none;
          min-width: 25.375rem;
          .editor-editable-content-container {
            background-color: $white;
          }
        }
      }
      .bulk-agency-client-dropdown {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0rem;
        .agency-client-wrapper {
          margin-top: 0px;
          margin-bottom: 1.5rem;
          width: 406px;
          height: 38px;
          &:hover,
          &.agency-client-show {
            border-color: #346aff;
            border-right-width: 0.0625rem !important;
          }
        }
      }
    }
  }
  .sending-settings-wrapper {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .bulk-update-email-accounts-row {
      margin: 0;
      flex-direction: column;

      &.daily-rampup-container {
        padding-left: 0.9375rem !important;
        margin: 0 !important;
        .bulk-update-email-error-wrapper {
          margin-top: 0.25rem !important;
        }
      }

      .col-md-8 {
        padding: 0;
        margin-bottom: 0.5rem;
      }

      &--inner {
        gap: 0.5rem;
      }

      .total-number-title,
      .setting-input-label {
        margin: 0 !important;
        color: $gray-15;
        font-size: 0.75rem !important;
        font-style: normal;
        font-weight: $font-weight-semibold !important;
        line-height: 1.125rem !important;
      }

      .sending-limit-input {
        padding: 0;
        width: 5.625rem;
        height: 2.25rem;
        box-shadow: none;
        .bs-input-number-input {
          height: 2.25rem;
        }
        .bs-input-number-error {
          .bs-input-number {
            border-color: $red-12;
          }
        }
      }
      .number-input-container {
        margin-top: 0.5rem;

        .bs-input-number-container {
          width: 5.625rem;
          height: 2.25rem;
          box-shadow: none;
          .bs-input-number-input-wrap {
            width: 100%;
          }
        }
      }
      .number-input {
        margin: 0rem 0.5rem !important;
      }
      .daily-rampup {
        margin: 0rem !important;
        &:nth-of-type(2) {
          margin-top: 0.625rem !important;
        }
      }
    }

    .bulk-update-email-error-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
      .icon {
        width: 0.875rem;
        height: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: 100%;
          color: $red-12;
        }
      }
      p {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1.25rem;
        color: $red-12;
        margin: 0;
        max-width: 23rem;
      }

      &.warning {
        align-items: flex-start;
        background-color: $yellow-11;
        padding: 0.5rem;
        border-radius: 0.25rem;
        .icon {
          margin-top: 0.25rem;
        }
      }
    }
  }
}

.email-account-confirmation-popup {
  .btn-solid-primary {
    min-width: 6.25rem;
    height: 2rem;
    border: none;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    transition: all 0.2s ease;
  }
  .btn-ghost-outlined {
    min-width: 6.25rem;
    height: 2rem;
    border: 0.0625rem solid $gray-13;
    font-weight: $font-weight-medium;
    line-height: 1.25rem;
    transition: all 0.2s ease;
    color: $gray-15;
    &:focus,
    &:hover {
      color: $gray-15;
      border-color: $gray-13;
    }
  }

  &.delete {
    p {
      margin: 0 !important;
    }
    .btn-solid-primary {
      background-color: $red-16 !important;
      &:focus,
      &:hover {
        background-color: $red-23 !important;
      }
    }
  }
}

.separator-line {
  width: 0.125rem;
  height: 2rem;
  background-color: #d1d5db;
}

.icon {
  font-size: 1.5rem;
}

.filter-count-badge {
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  background-color: #346aff;
  color: white;
  border-radius: 50%;
  padding: 0.125rem 0.375rem;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .add-btn-container {
//   .dropdown-container {
//     position: absolute;
//     top: 0.5rem;
//     left: 0.5rem;
//   }
// }

.globalSettings__Wrapper {
  .globalSettings__Header {
    margin-bottom: 0.5rem !important;
  }
}

.detail-page-tag {
  margin-bottom: 1.5rem;
  margin-left: 1.1875rem;

  .tag-row-container {
    display: flex;
    align-items: center;

    .tag-icon {
      margin-left: 0.5rem;
      color: $gray-12;
    }
    .prospect-tag-row {
      margin-left: 0.5rem !important;
    }
  }
}

.prospect-add-tag {
  color: $blue-11;
}

.cancel-btn {
  min-width: 4.9375rem;
  color: $gray-15;
  font-weight: $font-weight-medium;
  font-size: 0.875rem;
  padding: 0.375rem 1rem;
  background-color: $white;
  border: 1px solid $gray-5;
  margin: 0 1rem 0 0;
}

.submit-btn {
  min-width: 6.25rem !important;
  height: 2rem !important;
  border: none !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  background-color: $blue-11 !important;
  &:hover {
    background-color: #1e40af !important;
  }
}

.email-account-cancel-btn {
  min-width: 6.25rem !important;
  height: 2rem !important;
  border: 0.0625rem solid #e5e7eb !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
  transition: all 0.2s ease !important;
  color: #6b7280 !important;
  margin-right: 1rem;
  &:focus-visible {
    outline: none;
  }
  &:hover,
  &:active {
    color: #6b7280 !important;
    background-color: #f3f4f6 !important;
  }
}

.tooltip-hidden {
  display: none;
}

.tooltip-visible {
  display: block;
}

.email-accounts-table {
  .bs-checkbox-wrapper {
    .bs-checkbox-inner {
      border-radius: 4px;
    }
  }
  .switchToggle {
    .label {
      margin-bottom: 0 !important;
      margin-top: 0.2rem;
    }
  }
}

.icon-trulyinbox path {
  fill: $orange-21;
}

.icon-trulyinbox {
  margin-bottom: 0.0625rem;
}

.trulyinbox {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.info-icon-popover-btn {
  width: 0.875rem;
  height: 0.875rem;
  color: $gray-15;
  margin-left: 0.25rem;
  margin-bottom: 0.125rem;
  transition: all 0.2s ease;
  &:hover {
    color: $blue-11;
  }
}

.isInfoIconVisible {
  color: $blue-11 !important;
}

.isInfoIconNotVisible {
  color: $gray-15 !important;
  &:hover {
    color: $blue-11 !important;
  }
}

.cross-icon {
  color: $blue-15 !important;
}

.link {
  color: $gray-11 !important;
}

.status-cell {
  position: sticky;
  left: 3.6875rem;
  background-color: white;
  z-index: 1;
}

.name-cell {
  position: sticky;
  background-color: white;
  z-index: 1;
  box-shadow: none;
}

.name-header {
  position: sticky;
  top: 0;
  background-color: #f3f4f6 !important;
  z-index: 3;
  box-shadow: none;
}

.table-scroll-container.is-scrollable .name-header {
  box-shadow: 8.056px 0 15px -5px rgba(226, 230, 245, 0.5);
}

.table-scroll-container.is-scrollable .name-cell {
  box-shadow: 8.056px 0 15px -5px rgba(226, 230, 245, 0.5);
}

.status-header {
  position: sticky;
  left: 3.6875rem;
  top: 0;
  background-color: #f3f4f6 !important;
  z-index: 10;
}

.header-icon-component {
  display: flex;
  align-items: center;
}

.active-sequence-cell-header {
  padding-left: 1.5rem !important;
}

.check-cross-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.dropdown-item-custom {
  &:hover {
    background-color: $blue-17 !important;
  }
}

.with-toggle {
  left: 6.9375rem;
}
