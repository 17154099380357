.tag-button {
  height: 1.75rem !important;
  padding: 0.375rem 0.75rem !important;
  color: $gray-15 !important;
  background-color: transparent !important;
  border: 0.0625rem $gray-23 solid !important;
  border-radius: 0.25rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
  cursor: pointer;
  transition: all 0.2s ease !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  font-weight: $font-weight-medium !important;

  &:hover {
    border: 0.0625rem $blue-11 solid !important;
    color: $blue-11 !important;
  }
}

.tag-dropdown-view {
  position: absolute;
  margin-left: 21.65rem;
  margin-top: 2rem;
  background: white;
  cursor: pointer;
}

.menu-item {
  width: 100%;
  padding: 0.375rem 0.75rem;
  &:hover {
    background-color: $blue-17;
  }
}

.dropdown-container {
  border-radius: 0.25rem;
  border: 0.0625rem solid $gray-13;
  background: #fff;
  display: inline-flex;
  padding: 0.5rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  height: 5rem;
  width: 10.6875rem;
  list-style: none;
  margin: 0;
}

.tag-text {
  margin-top: 0.3rem;
}

.add-remove-tag-modal {
  .modal-header {
    border: none;
    min-height: 1.5rem;
  }
  .modal-dialog {
    max-width: 34.12rem;
  }
  .bs-modal-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .modal-body {
    height: 7.75rem;
    display: flex;
    flex-direction: column;
    max-height: 8rem;
  }

  .btn-ghost-primary {
    min-width: 6.25rem;
    height: 2rem;
    border-color: $gray-5;
    color: $gray-8;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
  }
  .btn-solid-primary {
    min-width: 6.25rem;
    margin-left: 1rem !important;
    height: 2rem;
    width: 6.25rem;
    background-color: $blue-11;
    font-weight: $font-weight-medium;
  }
  .btn-solid-primary:disabled {
    background-color: $blue-11;
    color: $white-text;
  }
}

.modal-header-title {
  margin-top: 1.5rem;
}

.p-text-tag {
  color: $blue-15 !important;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 20px;
}

.saved-tab-tags {
  .selected-tags-container {
    .bs-tag-blue-17 {
      background: $blue-13;
    }
  }
  &.remove-tag-modal {
    .selected-tags-container {
      .bs-tag-blue-17 {
        background: $red-19;
      }
    }
  }
}

.dropdown {
  &:hover {
    background: $blue-17;
  }
}

.tag-list-font {
  font-weight: $font-weight-medium;
  margin: 0;
}
