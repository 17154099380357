.integrations {
  min-height: 91vh;
  overflow-y: scroll;
  .crm-view-container {
    margin-top: 3.5rem !important;
  }
  .card {
    width: 16.5625rem;
    &.zapier-card {
      .card-body {
        img {
          width: auto;
        }
      }
    }
    .card-body {
      img {
        width: auto;
        height: 1.5rem;
      }
      .disconnect {
        display: none;
      }
      .more-action > .dropdown-custom {
        z-index: 1;
        .dropdown-menu-custom {
          padding-top: 0.375rem;
          padding-bottom: 0.375rem;
          min-width: 8.125rem;
          border: 1px solid $gray-13;
        }
        .btn-solid {
          padding: 0px;
        }
        .icon-configure {
          height: 1rem;
        }
      }
    }
    &:hover {
      box-shadow: 0px 0px 0.25rem $blue-19;
    }
    &.active {
      background: $blue-17;
      border-color: $blue-13;
    }
  }
}

.duein-input {
  font-weight: $font-weight-medium;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $popover-arrow-color;
}

.duein-days {
  display: flex;
  align-items: center;
}
.configuration {
  height: calc(100vh - 4rem) !important;
  overflow-y: hidden !important;
  background: $gray-19;
  &__content {
    .bs-r-6 {
      right: 0rem;
      top: -1rem;
      z-index: 2;
      .shd-button.outline-danger {
        background-color: transparent !important;
        border: 1px solid $red-18;
        color: $red-18;
      }
    }
    .bs-tabs > .bs-tabs-content-holder > .bs-tabs-content {
      overflow-y: scroll !important;
      height: calc(100vh - 14rem) !important;
      position: relative;
    }
    .fields {
      padding: 1.5rem;
      border: 1px solid $gray-29;
      border-radius: $border-radius;
      background-color: $gray-1;
      &__slots {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1.25rem;
        &-slot {
          padding: 1.125rem 0px;
          border-radius: 4px;
          border: 1px solid $gray-13;
          img {
            height: 1.5rem;
          }
          .crm-field {
            min-width: 10rem;
          }
          &:hover {
            background-color: $gray-19;
          }
        }
      }
    }
    .triggers {
      border: 1px solid $gray-29;
      height: calc(100vh - 14.5rem);
      position: relative;
      background-color: $gray-1;
      &__header {
        height: 90%;
        overflow-y: auto;
        &-options {
          padding: 1.5rem 1.375rem;
          border-collapse: collapse;
          &-option {
            width: 100%;
            border: 1px solid $gray-29;
            padding: 1.25rem 1.5rem;
            &:not(:last-child) {
              border-bottom: 0px;
            }
            .bs-select-box {
              width: 16.875rem;
              height: 1.875rem;
              background-color: $gray-25;
            }
            .action-container {
              height: 1.25rem;

              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.25rem;

              .separator {
                width: 0.0625rem;
                height: 1.25rem;
                border-radius: 0.0625rem;
                background: #e5e7eb;
              }

              i {
                color: #9ca3af;
                transition: all 0.2s ease;
                &:hover {
                  color: $blue-15;
                }
              }
            }
            &-selected {
              display: grid;
              row-gap: 2px;
              justify-content: end;
              div {
                width: 16.875rem;
                padding: 0.375rem 1rem;
              }
            }
          }
        }
      }
      &__btn-tooltip {
        margin-left: 0rem;
        .arrow {
          display: none;
        }
        .tooltip-inner {
          height: 1.575rem;
          padding: 0.375rem 0.75rem !important;
          font-weight: $font-weight-semibold !important;
          line-height: 1.125rem;
          color: $gray-1;
        }
      }
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    border-top: 1px solid $gray-29;
    background-color: $white;
  }
  .mapping {
    height: calc(100vh - 10rem);
    margin-top: 1.625rem;
    position: relative;
    border: 1px solid $gray-29;
    border-radius: $border-radius;
    background-color: $gray-1;
    &__header {
      height: 90%;
      overflow-y: auto;
      &-content {
        width: 29rem;
        .default-filed {
          width: 12.5rem;
          padding: 0.375rem 1rem;
          border: 1px solid $gray-29;
          border-radius: $border-radius;
        }
        .basic-multi-select {
          width: 12.5rem;
          .cs {
            &__control {
              background-color: $gray-25 !important;
            }
            &__single-value {
              color: $popover-arrow-color;
            }
            &__indicator {
              color: $gray-11 !important;
              &-separator {
                background-color: $gray-13 !important;
              }
            }
          }
        }
      }
    }
  }
}

.connect-title {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  .connect-title-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      color: #d97706;
    }
    &.upload-icon {
      svg {
        color: $blue-11;
      }
    }
  }
  h1 {
    font-weight: $font-weight-semibold;
  }
}
.trigger-tooltip {
  .tooltip-inner {
    max-width: 16.875rem !important;
  }
}

.tag-alert {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;

  border-radius: 4px;
  border: 1px solid #fef3c7;
  background: #fffbeb;

  .tag-alert-icon {
    width: 1rem;
    height: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      color: #b45309;
    }
  }

  p {
    margin: 0;
    color: #92400e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.trigger-options-dropdown {
  li {
    word-break: normal;
  }
}

.zapier-title {
  margin-top: 2.625rem;
}
