.editor {
  transition: all 0.3s;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $gray-19;

  border: 0.0625rem solid $blue-14;
  border-radius: 0.25rem;

  position: relative;

  &:hover {
    border-color: $blue-5;
    border-right-width: 0.0625rem !important;
    + {
      .bs-form-item-children-icon {
        opacity: 0;
        transition: opacity 0.24s linear 0.24s;
      }
    }
    .bs-input-number-handler-wrap {
      opacity: 1;
    }
  }
  &:focus,
  &:focus-within {
    border-color: $blue-5;
    border-right-width: 0.0625rem !important;
    outline: 0;
    box-shadow: $input-number-shadow;
  }

  &.error {
    border: 0.0625rem solid $red-6;
    &:hover {
      border-color: $red-6;
    }
    &:focus,
    &:focus-within {
      box-shadow: $input-error-shadow;
    }
  }

  .editor-editable-content-container {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    scroll-behavior: smooth;

    .editor-editable-content {
      flex: 1;
      outline: 0 solid transparent;
      font-family: sans-serif;
      font-size: 0.8125rem;

      // Setting default values for h1, h2, h3, h4, h5, h6, and p tags within the editor
      // So it should look consistent in editor as well as across browsers when sending email
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold;
        display: block;
        margin-left: 0;
        margin-right: 0;
      }

      h1 {
        font-size: 2em;
        margin-top: 0.67em;
        margin-bottom: 0.67em;
      }

      h2 {
        font-size: 1.5em;
        margin-top: 0.83em;
        margin-bottom: 0.83em;
      }

      h3 {
        font-size: 1.17em;
        margin-top: 1em;
        margin-bottom: 1em;
      }

      h4 {
        font-size: 1em;
        margin-top: 1.33em;
        margin-bottom: 1.33em;
      }

      h5 {
        font-size: 0.83em;
        margin-top: 1.67em;
        margin-bottom: 1.67em;
      }

      h6 {
        font-size: 0.67em;
        margin-top: 2.33em;
        margin-bottom: 2.33em;
      }

      p {
        margin-top: 1em;
        margin-bottom: 1em;
        display: block;
        margin-left: 0;
        margin-right: 0;
      }

      &.mce-content-body:not([dir='rtl']) blockquote {
        border-left: 2px solid #ccc;
        margin-left: 1.5rem;
        padding-left: 1rem;
      }

      &.mce-content-body[dir='rtl'] blockquote {
        border-right: 2px solid #ccc;
        margin-right: 1.5rem;
        padding-right: 1rem;
      }

      pre {
        white-space: pre-line;
      }
    }
  }

  .toolbar-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2rem;

    background: $gray-3;
    border-bottom: 0.0625rem solid $blue-14;
    box-sizing: border-box;
  }

  .template-button {
    display: flex;
    align-items: center;
    margin-right: 0.625rem;
    border: none;
    background-color: transparent;
    p {
      font-family: $font-family-base;
      font-weight: normal;
      margin: 0;
      margin-left: 0.1875rem;
      color: $gray-10;
    }
  }

  .tox-tinymce-inline .tox-editor-header {
    border: none;
    margin-bottom: 0;
    .tox .tox-toolbar-overlord {
      background-color: transparent;
    }
  }

  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background: none;
    background-color: $gray-3;
    height: 1.875rem;
  }

  .tox {
    .tox-toolbar__group {
      margin: 0;
      height: 100%;
    }
    .tox-tbtn {
      margin: 0;
      font-family: $font-family-base;
      // Can't use Typography class here
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: $gray-10;
      width: 1.5rem;
      height: 1.5rem;
    }
    .tox-tbtn--select {
      width: auto;
    }
    .tox-tbtn--bespoke .tox-tbtn__select-label {
      width: 5em;
    }
    button[aria-label='Font sizes'].tox-tbtn--bespoke .tox-tbtn__select-label {
      width: 2.5em;
    }
    .tox-split-button {
      .tox-tbtn {
        .tox-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .tox-split-button__chevron {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tox-menu {
      max-height: 16.25rem !important;
    }
    .tox-collection--list .tox-collection__group:first-child {
      min-width: 5.625rem;
    }
  }

  .add-merge-tag-option-container {
    margin-left: 1rem;
    border: 0.0625rem solid $gray-5;
    border-radius: 0.25rem;
    background: $gray-1;
    width: 2.375rem;
    height: 2.375rem;
    color: $blue-18;
    padding: 0.25rem;
    cursor: pointer;

    &:hover,
    &:focus {
      background: $gray-3;
    }

    &:active {
      background: $gray-20;
    }

    .add-merge-tags-option {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .merge-tag-icon {
        font-size: 1.5rem;
      }
    }
  }

  &.unsubscribe-editor {
    padding: 0 !important;
    border-radius: 4px;
    border: 0.0625rem solid $blue-14;
    max-width: 50em;
    .editor-editable-content-container {
      border: none;
    }
    .toolbar-container-for-unsubscribe-tag {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 50em;
      border-radius: 0;
    }

    .unsubscribe-container {
      background-color: $gray-3;
      height: 100%;
      padding: 0 0.5rem 0 0;
      flex-basis: 35%;
    }
    .toolbar-flex-size {
      flex-basis: 65%;
    }

    .unsubscribe-setting-editor {
      .tox-tinymce-inline .tox-editor-header {
        border: none;
      }
    }
  }
}

.attachments-list {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding-top: 0.9375rem;
}

.drag-n-drop {
  position: absolute;
  top: 1.875rem;
  left: 0;

  width: 100%;
  height: calc(100% - 1.875rem);

  background: rgba(255, 255, 255, 0.96);
  border-radius: 0px 0px 0.25rem 0.25rem;
  border-top: 0.0625rem solid $blue-14;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  &--title {
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: $blue-15;
    margin: 0;
  }

  &--text {
    font-weight: $font-weight-normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    color: $gray-15;
    margin: 0;
  }
}

.email-content-error {
  margin: 0;
  font-size: 0.875rem !important;
  background: $red-19 !important;
  border-radius: 0 !important;
  border-top: 0.0625rem solid $red-11 !important;
}

pre {
  color: inherit;
  display: inherit;
  font-size: inherit;
}

.editor {
  .tox {
    .tox-autocompleter {
      .tox-menu {
        width: 14rem;
        max-height: 18.25rem !important;
        overflow-y: auto;

        border-radius: 4px;
        border: 1px solid #e5e7eb;
        background: #fff;

        box-shadow: 2px 2px 16px -3px rgba(4, 21, 76, 0.16),
          8px 16px 56px -24px rgba(156, 157, 161, 0.24);

        .tox-collection__group {
          padding: 4px 0 12px 0px !important;

          .tox-collection__item {
            padding: 0 !important;

            .group-header {
              width: calc(100% - 24px);
              padding: 8px 12px 4px 12px;
              color: #9ca3af;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              cursor: default;
            }

            .group-header-hidden {
              display: none;
            }

            .tag-item {
              width: calc(100% - 24px);
              padding: 6px 12px;
              color: #1f2937;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }

            &:hover {
              background-color: transparent !important;

              .tag-item {
                background-color: #eff6ff !important;
              }
            }
          }
          .tox-collection__item--active {
            background-color: transparent !important;

            .tag-item {
              background-color: #eff6ff !important;
            }

            &:hover {
              .tag-item {
                background-color: #eff6ff !important;
              }
            }
          }
        }
      }
    }
  }
}
