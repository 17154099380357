.leads-tabbing {
  .bs-tabs {
    .bs-tabs-nav {
      height: 2.938rem;
      margin-bottom: 0px;
      color: $gray-30;

      .bs-tabs-nav-wrap {
        padding-left: 1.4375rem;

        .bs-tabs-nav-list {
          .bs-tabs-tab {
            margin-right: 1.25rem;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            color: $gray-30;

            &.bs-tabs-tab-active {
              color: $blue-11;
              font-weight: $font-weight-semibold;
            }
          }

          .bs-tabs-ink-bar-animated {
            height: 0.1875rem;
            border-radius: 0.125rem 0.125rem 0 0;
          }
        }
      }
    }
  }
}

.no-search-lead {
  height: calc(100vh - 6.938rem);
  display: flex;
  align-items: center;
  justify-content: center;

  .no-search-result {
    margin: 0;
  }
}

// Alert Message
.alert-message{
  .no-search-lead{
    height: calc(100vh - 9.2505rem);
  }
}