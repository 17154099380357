.email-modal {
  &--header {
    margin-bottom: 0.75rem;
  }

  &--editor {
    display: flex;
    height: 100%;
    max-height: calc(100% - 80px);
    position: relative;
  }

  .with-one-inputs-one-error {
    max-height: calc(100% - 105px);
  }
  .with-two-inputs {
    max-height: calc(100% - 110px);
  }
  .with-two-inputs-one-error {
    max-height: calc(100% - 135px);
  }
  .with-two-errors {
    max-height: calc(100% - 155px);
  }

  .modal-dialog {
    max-width: 80.4rem;
  }

  .modal-header {
    padding-left: 0px;
    .step-info-wrapper {
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      height: 2.25rem;
      background-color: $gray-16;
      border-radius: 0.25rem;
      .step-variant {
        margin-left: 1.125rem;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 0.25rem;
        background-color: $gray-1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .modal-content {
    min-height: 90vh;
  }

  .sidebar-header {
    padding-left: 1.5rem;
  }

  .input-wrapper-preheader {
    margin-top: 0.75rem;
  }

  .email-modal-input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    padding: 0 0.9375rem;
    background-color: $gray-19;
    border: 0.0625rem solid $blue-14;
    box-sizing: border-box;
    border-radius: 0.25rem;
    &.title-input-wrapper {
      margin-bottom: 1rem;
    }
    &.input-with-error {
      border: 1px solid $red-12;
      &.title-input-wrapper {
        margin-bottom: 0;
      }
    }
    .email-modal-input-label {
      margin: 0;
      min-width: 3.125rem;
      font-family: $font-family-base;
      font-weight: 600;
      display: flex;
      align-items: center;
      color: $gray-15;
    }

    .label-for-preheader {
      min-width: 4.5rem;
    }

    .email-modal-input-divider {
      width: 0.125rem;
      height: 1.25rem;
      margin: 0px 1.25rem;
      background: $blue-14;
      border-radius: 0.0625rem;
    }
    .email-modal-input-box {
      height: 1.25rem;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      align-items: center;
      &:hover,
      &:focus-within {
        border: none;
        border-color: transparent;
        border-right-width: 0;
        box-shadow: none;
      }
      input {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $gray-17;
        background-color: transparent;
        &::placeholder {
          color: $gray-11;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
      &.title-input {
        input {
          font-weight: 500;
          color: $blue-15;
        }
      }
      &.bs-input-affix-wrapper-disabled {
        .emoji-icon,
        .add-merge-tag-option-container,
        .spintax-icon {
          cursor: not-allowed;
          color: $gray-12;
          .merge-tag-icon {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .email-modal-input-error-wrapper {
    height: 20px;
  }

  .email-modal-input-error {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1.125rem;
    color: $red-12;
    margin-top: 3px;
    img {
      margin-right: 5px;
      margin-top: -1px;
    }
    &.with-margin {
      margin-bottom: 1rem;
    }
  }

  .add-merge-tag-option-container {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    user-select: none;
    .merge-tag-text {
      margin: 0;
      font-family: $font-family-base;
      font-weight: normal;
      color: $blue-15;
    }
    .merge-tag-icon {
      width: 1rem;
      height: 1rem;
    }
    .add-merge-tag-option {
      border: 0.0625rem solid $gray-5;
      border-radius: 0.25rem;
      background: $gray-1;
      color: $blue-18;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.25rem;
      &:hover,
      &:focus {
        background: $gray-3;
      }
      &:active {
        background: $gray-20;
      }
    }
  }

  .subject-length {
    margin: 0;

    font-style: normal;

    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.25rem;
    color: $gray-15;
  }

  .helper-wrapper {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    width: 100%;
    height: 1.25rem;
    &.with-recommendation {
      justify-content: space-between;
    }
    .recommendation {
      font-family: $font-family-base;
      font-style: normal;
      font-weight: 600;
      font-size: 0.625rem;
      line-height: 1.25rem;
      color: $orange-12;
      margin: 0;
      margin-top: 3px;
    }
  }

  .subject-header {
    display: flex;
    align-items: center;
    .subject-variable {
      flex: 1;
      display: flex;
      align-items: center;
      label {
        margin: 0 1rem 0 0;
        color: $gray-10;
      }
    }
    .bs-select-box {
      width: 9.25rem;
      height: 2.5rem;
      margin-left: 1rem;
    }
  }

  .modal-body {
    padding: 1rem 1.5rem !important;
    overflow-y: unset;
    height: calc(90vh - 120px);
    display: flex;
    flex-direction: column;

    .email-editor-height {
      height: calc(90vh - 230px);
    }

    .email-editor-with-preheader {
      height: calc(90vh - 250px);
    }

    .subject-preheader-util-container {
      margin-top: 0.375rem;
      .bs-checkbox-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 1rem;
        .bs-checkbox {
          .bs-checkbox-inner {
            border-radius: 0.25rem;
          }
          & + span {
            padding-left: 0.375rem;
            padding-right: 0;

            user-select: none;

            color: $gray-15;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: $font-weight-medium;
            line-height: 1rem;
          }
        }
      }
      .info-icon {
        width: 0.875rem;
        height: 0.875rem;

        margin-left: 0.25rem;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
          color: $gray-15;
        }
      }
    }

    .preheader-text-label {
      padding: 0;
      font-style: normal;
      font-weight: $font-weight-semibold;

      font-size: 0.75rem;
      color: $blue-11;
      background: transparent;
      border: none;
      line-height: 1.125rem;
    }

    .email-editor {
      margin: 1rem 0 0 0;
      height: calc(90vh - 13rem);
      textarea {
        height: 100%;
      }
    }
    &.preview-body {
      background: $gray-2;
      .preview-header {
        display: flex;
        align-items: center;
        .prev-mail {
          color: $gray-10;
          font-size: $text-18;
          margin-right: 1rem;
          width: 1.875rem;
          height: 1.875rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;
          cursor: pointer;
          &:hover {
            background: $gray-4;
          }
        }
        .prospect-count {
          color: $gray-10;
          margin: 0 1.5rem;
        }
        .next-mail {
          color: $gray-10;
          font-size: $text-18;
          width: 1.875rem;
          height: 1.875rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $border-radius;
          cursor: pointer;
          &:hover {
            background: $gray-4;
          }
        }
        .bs-input-affix-wrapper {
          flex: 0 18.75rem;
        }
      }
      .preview-body {
        margin: 1rem 0;
        border: 1px solid $gray-5;
        border-radius: $border-radius;
        background: $gray-1;
        padding: 1.5rem;
        p {
          color: $gray-10;
        }
      }
    }

    .merge-tag-icon {
      font-size: 1.2rem;
      color: #bfbfbf;
      cursor: pointer;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    .email-modal-footer {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin: 0;
      min-height: 4rem;
      max-height: 4rem;
      height: 4rem;
      padding: 1rem 1.5rem;
      &.footer-part {
        justify-content: space-between;
      }
      .send-test-email-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 1.5rem;
        .email-input-container {
          width: 20rem;
          display: flex;
        }
      }
      .switchToggle {
        flex: 0 8.75rem;
        display: flex;
        width: 150px;
        label {
          white-space: nowrap;
          margin: 0;
          color: $gray-10;
        }
      }
      .test-mail-block {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        label {
          margin: 0 0.75rem 0 0;
          color: $gray-10;
        }
        .bs-input-affix-wrapper {
          width: 12.5rem;
        }
      }
    }
    .day-input-spinner {
      display: flex;
      align-items: center;
      label {
        margin: 0 0.75rem 0 0;
        font-family: $font-family-base;
        color: $blue-15;
      }
      .day-number {
        min-width: 3.5rem;
        margin: 0 0.75rem 0 0;
        width: 4rem;
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          color: $blue-15;
        }
      }
    }
  }

  .modal-sidebar {
    .modal-body {
      max-height: calc(90vh - 3.5rem);
      overflow: hidden;

      .sidebar-body-content {
        height: 100%;
        .bs-tabs {
          height: 100%;
          .bs-tabs-content-holder {
            overflow-y: hidden;
            .bs-tabs-content {
              height: 100%;

              .tab-scrollbar-container {
                height: 100%;
                overflow-y: auto;
                margin-right: 0.3125rem;
                padding: 0 1.1875rem 0 1.5rem;
                .os-scrollbar-track {
                  width: 0.4375rem !important;
                  .os-scrollbar-handle {
                    width: 0.4375rem !important;
                    border-radius: 0.25rem;
                    background: #e5e7eb;
                  }
                }
              }

              .email-preview-tab {
                height: 100%;
                .email-preview-tab-inner {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }

    .bs-tabs-nav {
      margin: 0 1.5rem;
    }
    .bs-tabs-content-holder {
      padding: 0;
      padding-top: 1rem;
      position: relative;
      .bs-tabs-content {
        width: 100%;
      }
    }

    .bs-tabs-nav-list {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    .bs-tabs-tab {
      width: 7.9375rem;
      height: 2.0625rem;
      padding: 0 !important;
      padding-bottom: 0.75rem !important;
      margin: 0 !important;

      .bs-tabs-tab-btn {
        width: 100%;
        height: 1.25rem;
      }
      .sidebar-tabs {
        &--tab {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;

          svg {
            width: 1.25rem;
            height: 1.25rem;
            color: #9ca3af;
            transition: all 0.2s ease;
          }
          span {
            color: #9ca3af;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 1.25rem;
            transition: all 0.2s ease;
          }
        }
      }

      &:hover,
      &.bs-tabs-tab-active {
        .sidebar-tabs {
          &--tab {
            svg,
            span {
              color: $blue-11;
            }
          }
        }
      }
    }

    .email-suggestions-wrapper {
      width: 100%;
      background-color: $gray-19;
      border-radius: 0.25rem;
      padding: 1.25rem 0.9375rem;
      margin: 1.25rem 0;
      .email-suggestion {
        margin-bottom: 1.25rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        p {
          margin: 0;
        }
        .email-suggestion-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .email-suggestion-title {
            font-weight: bold;
            font-size: 0.625rem;
            line-height: 0.75rem;
            color: $blue-15;
          }
          .email-suggestion-length {
            font-weight: $font-weight-semibold;
            font-size: 0.625rem;
            line-height: 0.75rem;
            color: $gray-12;
            &.with-tooltip {
              border-bottom: 1px dashed $gray-11;
              box-sizing: border-box;
            }
          }
        }
        .email-suggestion-progress {
          height: 0.3125rem;
          border-radius: 0.25rem;
          margin-top: 0.625rem;
          margin-bottom: 0.3125rem;
          background-color: $gray-13;
          .progress-bar {
            border-radius: 0.25rem;
            background-color: transparent;
            transition: 1.5s all ease;
          }
        }
        .email-suggestion-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .email-suggestion-helper-text,
          .email-suggestion-status {
            font-weight: $font-weight-medium;
            font-size: 0.625rem;
            line-height: 0.75rem;
            color: $gray-15;
          }
          .email-suggestion-helper-text {
            font-weight: $font-weight-normal;
          }
        }
        .too-short {
          color: $red-12 !important;
          .progress-bar {
            background-color: $red-12 !important;
          }
        }
        .short {
          color: $orange-14 !important;
          .progress-bar {
            background-color: $orange-14 !important;
          }
        }
        .ideal {
          color: $green-14 !important;
          .progress-bar {
            background-color: $green-14 !important;
          }
        }
        .lengthy {
          color: $orange-14 !important;
          .progress-bar {
            background-color: $orange-14 !important;
          }
        }
        .too-lengthy {
          color: $red-12 !important;
          .progress-bar {
            background-color: $red-12 !important;
          }
        }
        .poor {
          color: $red-12 !important;
          .progress-bar {
            background-color: $red-12 !important;
          }
        }
        .average {
          color: $orange-14 !important;
          .progress-bar {
            background-color: $orange-14 !important;
          }
        }
        .good {
          color: $green-14 !important;
          .progress-bar {
            background-color: $green-14 !important;
          }
        }
        .excellent {
          color: $green-14 !important;
          .progress-bar {
            background-color: $green-14 !important;
          }
        }
      }
    }

    .content-guide-info-wrapper {
      width: 100%;
      margin-bottom: 1.25rem;

      display: flex;
      justify-content: center;
      align-items: center;

      .content-guide-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
      }

      .content-guide-info-badge {
        width: 1.4375rem;
        height: 0.75rem;

        padding: 0.1875rem;

        display: flex;
        justify-content: center;

        border-radius: 0.125rem;
        border: 0rem solid #f5f5f5;
        background: #10b981;

        span {
          color: $white;
          text-align: center;
          font-size: 0.4375rem;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 0.5rem;
        }
      }

      h4 {
        color: $blue-15;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 0.875rem;
        margin: 0;
      }
    }

    .need-help-cta {
      width: 24.8125rem;
      padding: 0 0.9375rem 0.9375rem 0.9375rem;
      margin-top: 1.875rem;
      margin-bottom: 1.875rem;
      .need-help-cta-title {
        font-style: normal;
        font-weight: $font-weight-semibold;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        color: $blue-15;
        margin: 0;
        margin-bottom: 0.625rem;
      }
      .need-help-cta-content {
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        color: $gray-15;
        margin: 0;
        a {
          color: $blue-11;
          text-decoration: underline;
          &:hover {
            color: $blue-11;
          }
        }
      }
    }
  }
}

.content-guide-tooltip {
  .tooltip-inner {
    max-width: 25rem !important;
  }
}

.pre-header-tooltip {
  .tooltip-inner {
    max-width: 15rem !important;
  }
}

.text-only-email-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  height: 1.375rem;
  padding: 0rem 0.5rem;
  background-color: $white;
  border-radius: 0.6875rem;
  cursor: pointer;

  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #34d399;
    border-radius: 50%;
  }
  p {
    color: #9ca3af;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0;
  }
}

.text-only-email-popover {
  width: 285px;
  max-width: none;
  height: 4.625rem;

  border-radius: 0.25rem !important;
  border: 0.0625rem solid #f3f4f6 !important;
  background: #fff;

  padding: 0 !important;

  .bs-popover-inner-content {
    padding: 0.625rem !important;
  }

  .content {
    display: flex;
    gap: 0.5rem;
  }

  svg {
    color: #9ca3af;
  }

  p {
    color: $blue-15;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0;

    a {
      color: $blue-11;
      text-decoration: none;
    }

    span {
      color: $blue-11;
    }
  }
}

.html-content-detected-modal {
  .modal-dialog {
    min-height: calc(100% - 3.5rem) !important;
    margin-top: 1.75rem !important;

    .delete-account-title-icon {
      svg {
        color: $orange-14;
      }
    }
  }
  .bs-checkbox-wrapper {
    span {
      color: #1f2937;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.nudge-text-only-email-wrapper {
  width: 24.8125rem;

  border-radius: 0.25rem;
  background: $blue-17;
  margin-top: 1.25rem;
  padding: 0.9375rem 0.5625rem 0.9375rem 0.9375rem;

  .nudge-text-only-email-badge {
    padding: 0 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.8125rem;
    height: 0.875rem;

    background: $green-13;
    border: 0.0625rem solid $green-13;
    border-radius: 0.25rem;

    font-weight: $font-weight-semibold;
    font-size: 0.5rem;
    line-height: 0.875rem;
    color: $white;

    margin-bottom: 0.5rem;
  }

  h3 {
    color: $blue-15;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 0.875rem;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  p {
    width: 92%;
    margin: 0;
    color: $gray-15;
    font-size: 0.625rem;
    line-height: 1rem;
  }
}

.email-preview-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .email-preview-tab-inner {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .no-email-content-container {
      height: calc(100% - 4rem);
    }
    .no-email-content {
      width: 11.875rem;
      height: 8rem;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      p {
        color: #9ca3af;
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin: 0;
      }
    }
  }

  .to-subject-render-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    p {
      color: $blue-15;
      font-size: 0.8125rem;
      font-weight: $font-weight-normal;
      line-height: 1.25rem;
      margin: 0;

      word-break: break-all;

      span {
        font-weight: $font-weight-semibold;
      }
    }
  }

  .content-preview-container {
    color: $gray-24;
    font-size: 0.8125rem;
    line-break: strict;
    // Setting default values for h1, h2, h3, h4, h5, h6, and p tags within the email preview
    // So it should look consistent in preview as well as across browsers when sending email
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    h1 {
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
    }

    h2 {
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
    }

    h3 {
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    h4 {
      font-size: 1em;
      margin-top: 1.33em;
      margin-bottom: 1.33em;
    }

    h5 {
      font-size: 0.83em;
      margin-top: 1.67em;
      margin-bottom: 1.67em;
    }

    h6 {
      font-size: 0.67em;
      margin-top: 2.33em;
      margin-bottom: 2.33em;
    }

    p {
      margin-top: 1em;
      margin-bottom: 1em;
      display: block;
      margin-left: 0;
      margin-right: 0;
    }

    pre {
      white-space: pre-line;
    }
  }
}

.email-modal
  .email-modal-input-wrapper
  .email-modal-input-box.bs-input-affix-wrapper-disabled
  .spin-text {
  cursor: not-allowed !important;
}

.h-18px {
  height: 1.125rem;
}
