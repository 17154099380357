.custom-domain-container {
  .custom-domain-empty-screen {
    width: 100%;
    height: calc(100% - 65px);

    display: flex;
    justify-content: center;
    align-items: center;

    .shd-empty-list {
      height: 25rem;
      max-height: 25rem;
      min-height: 25rem;
      gap: 1.5rem;

      &--image {
        width: 8.25rem;
        height: 8.1875rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &--body {
        gap: 0.5rem;
      }

      &--title {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }

      &--description {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: $gray-15;
        max-width: 46.0625rem;
      }

      &--footer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.25rem;
        margin-top: 0.5rem;
      }
    }
  }

  .custom-domain-header {
    .custom-domain-add-button {
      align-self: flex-end;
    }
  }

  .table-list .react-bootstrap-table .table-organism .table-organism-body {
    tr:hover {
      background: #f9fafb;
      box-shadow: 0.125rem 0px 0.25rem rgba(31, 41, 55, 0.16);
      transition: all 0.2s ease;
    }

    td:first-of-type {
      border-left: 0.0625rem solid #e5e7eb;
    }
    td:last-of-type {
      border-right: 0.0625rem solid #e5e7eb;
    }
  }
}
.custom-domain-modal-container {
  .modal-title {
    margin-top: 0.25rem;
    font-weight: 600;
  }

  .multi-select {
    min-height: 0.5rem;

    &__control {
      min-height: 0.5rem;
    }
  }
  .dns-record-container {
    background-color: $gray-2;
    padding: 0.75rem;
    margin-top: 0.75rem;
    .dns-record-header {
      font-size: 0.875rem;
      font-weight: 600;
    }
    .sh-copy {
      cursor: pointer;
    }
  }
  .dns-info-alert {
    margin-top: 1rem;
    .info-icon {
      margin-top: 0.25rem;
      align-self: flex-start;
    }
  }
  .modal-text {
    font-size: 0.875rem;
  }
  .modal-content {
    min-width: 36rem;
  }
  .modal-body {
    padding: 16px 24px !important;
  }
  .modal-footer {
    justify-content: space-between;
  }
}
