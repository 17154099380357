.header-banner-container {
  display: flex;
  align-items: flex-start;
  background: $red-17;
  padding: 0.625rem 1rem;
  // margin-top: 0.9375rem;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  .icon {
    margin-right: 0.734375rem;
    margin-top: -0.1rem;
    .bs-banner-danger{
      border: none;
    }
    .shd-alert{
      .shd-alert--icon img{
        margin-top: -1.2rem;
      }
    }
  }
  span {
    color: $red-12;
    font-size: 0.875rem;
    margin-top: 0.35rem;
    a {
      text-decoration: underline;
    }
  }
}

.subscription-banner {
  border-width: 0;
  .upgrade-link {
    color: $blue-11;
  }
}
