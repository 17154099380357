.page-header {
  color: $gray-10;
  margin: 0;
  padding: 1rem 1.5rem !important;
  height: 3.125rem;
  background: $gray-1;
  border-bottom: 1px solid $gray-13;
  box-shadow: 0px 1px 4px $gray-13;
}

.header-with-notification {
  align-items: center;
  justify-content: space-between;
}

.hover-on-header {
  color: $gray-11 !important;
  font-size: 1.25rem !important;
  width: 1.875rem;
  height: 1.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-on-header:hover {
  background-color: $blue-17 !important;
  color: $gray-15 !important;
  border-radius: 50%;

  .custom-position {
    border-color: $blue-17 !important;
  }
}

.single-sequence-header {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex: 1;
  overflow: hidden;

  height: 1.875rem;

  &--title {
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $blue-15;
    transition: all 0.2s ease;
    cursor: pointer;
    text-decoration: none !important;

    &:hover {
      color: $blue-11;
    }
  }

  &--icon {
    width: 0.75rem;
    height: 1.25rem;
    font-size: 1rem;
    color: $blue-15;
  }

  .sequence-name {
    flex: 1;
    overflow: hidden;

    // width: 19.375rem;
    height: 1.875rem;
    padding: 0.125rem;

    // display: flex;
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;

    .hidden-span {
      visibility: hidden;
      white-space: pre; /* Prevent collapsing spaces */
      position: absolute;
      font-family: inherit;
      font-size: 1rem;
    }

    &--input {
      width: auto;
      min-width: 3.125rem;
      // width: 100%;
      height: 100%;

      margin: 0;
      padding: 0.1875rem;

      font-weight: $font-weight-semibold;
      font-size: 1rem;
      line-height: 1.25rem;

      color: $blue-15;
      // text-overflow: ellipsis;

      border-radius: 0.25rem;
      border: 0.125rem solid transparent;

      &:hover,
      &:focus,
      &:focus-within {
        border-color: $blue-11;
        outline: none;
        box-shadow: none;
        appearance: none;
      }
      &.disabled {
        &:hover,
        &:focus,
        &:focus-within {
          border-color: transparent;
        }
      }
    }
  }

  .switch-mt {
    margin-top: 0.875rem;
  }
}

.steps-top-action {
  display: flex;
  align-items: center;
  margin-left: 3rem;

  .switchToggle {
    min-width: 6.25rem;

    label {
      margin: 0 1.5rem 0 0;
      top: 0.1875rem;
      min-width: 2.5rem;
      height: 1.25rem;

      span {
        font-weight: $font-weight-semibold;
      }
      &::after {
        width: 0.875rem;
        height: 0.875rem;
        top: 0.1875rem;
        left: 3px;
      }
    }
  }

  .dropdown-item-custom {
    i {
      font-size: 1.125rem;
      top: 0.125rem;
      margin-right: 0.5rem;
      color: $blue-6;
    }
  }

  a {
    span {
      color: $gray-8;
    }

    &:hover {
      text-decoration: none;

      span {
        color: $gray-10;
      }
    }
  }
}

#HW_badge_cont {
  position: absolute !important;
  top: -0.5rem;
}

.agency {
  &--client-name {
    color: $blue-15;
    border-left: 0.0625rem solid $blue-14;
    border-radius: 0.0625rem;
    padding-left: 1.25rem;
    margin-left: 1.25rem;
  }
}

.chrome-webstore {
  height: 1.3rem;
  width: 1.3rem;
  .icon-blue {
    display: none;
  }

  &:hover {
    .icon-grey {
      display: none;
    }

    .icon-blue {
      display: block;
    }
    color: $gray-15;
  }

  img {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.graduation-hat {
  height: 1.5rem;
  width: 1.5rem;
  &:hover {
    color: $gray-15;
  }
}

.headset {
  height: 1.3rem;
  width: 1.3rem;
  &:hover {
    color: $gray-15;
  }
}

.bolt {
  height: 1.4rem;
  width: 1.4rem;
  &:hover {
    color: $gray-15;
  }
}

.bs-banner {
  border-width: 1px;
  border-style: solid;
  border-radius: 1.25rem;
  padding: 0.3125rem 1rem;

  &__cta {
    text-decoration-line: underline;
    text-underline-offset: 0.1875rem;
  }

  &-warning {
    background-color: $yellow-11;
    border-color: $orange-18;
  }

  &-danger {
    background-color: $red-17;
    border-color: $red-19;
  }

  &-secondary {
    background-color: $gray-19;
    border-color: $blue-22;

    &:hover {
      border-color: $blue-11;

      span {
        color: $blue-11;
      }
    }
  }
}

.mb--2 {
  margin-bottom: -2px;
}

.beta-badge-blue {
  background-color: $blue-17;
  color: $blue-24;
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  width: 2.9375rem;
  height: 1.5rem;

  span {
    font-size: 0.875rem;
    font-weight: $font-weight-normal;
  }
}
