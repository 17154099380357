.growth-hub {
  display: flex;
  grid-gap: 1.5625rem;
  height: 92%;
  min-width: 76rem;
  width: 100%;
  &__hide {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
  }
  &__left-container {
    min-width: 20.5rem;
    width: 24rem;
    height: 79%;
    transition: visibility 0s, opacity 0.5s linear;
    .lesson-container {
      height: 95%;
      overflow-y: auto;
      .shd-accordion {
        gap: 0px;
        .accordion__item {
          border: 1px solid $gray-13 !important;
          border-bottom: 0px !important;
          border-radius: 0px;
          box-shadow: none;
          &:last-child {
            border-bottom: 1px solid $gray-13 !important;
          }
          .accordion__heading {
            display: flex;
            align-items: center;
            padding: 0.75rem 1.25rem;
          }

          .accordion__button {
            background-color: transparent !important;
            padding: 0px;
            position: relative;
            &::before {
              position: absolute;
              right: 0px;
              color: $gray-15;
              top: 0.5rem;
              width: 0.5rem;
              height: 0.5rem;
            }
            .circular-progress {
              height: 1.5rem;
              width: 1.5rem;
            }
            &:hover {
              .hover-underline {
                text-decoration: underline;
              }
            }
          }

          .accordion__panel {
            padding: 0px;
            border-top: 1px solid $gray-13;
            .chapter {
              position: relative;
              &-item {
                padding: 0.5rem 1.5rem;
                &:first-child {
                  margin-top: 0.25rem;
                }
                &:last-child {
                  .chapter-track {
                    &::before {
                      content: none;
                    }
                  }
                }
                &:hover {
                  .hover-underline {
                    text-decoration: underline;
                  }
                }
              }
            }
            .chapter-track {
              width: 1rem;
              height: 1rem;
              border-radius: 60%;
              border: 1px solid $gray-11;
              background-color: $gray-1;
              margin-top: 0.1875rem;
              margin-right: 0.75rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              .gray-txt-1 {
                margin-top: 0.125rem;
              }
              &-current {
                border: 1px solid $green-16;
              }
              &-completed {
                border: 1px solid $green-16;
                background-color: $green-16;
              }

              &::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 2.4rem;
                top: 0.9rem;
                background-color: $gray-11;
              }
            }
          }
        }
      }
    }
  }
  &__right-container {
    padding-bottom: 1.25rem;
    width: 100%;
    transition: height 0.3s ease-out;
    position: relative;
    .master-class-content {
      height: 100%;
      border: 1px solid $gray-16;
      border-radius: 0.25rem;
      background-color: $gray-1;
      &__header {
        padding: 0.75rem 1.5rem;
        border-bottom: 1px solid $gray-16;
        box-shadow: 0px 1px 4px $gray-16;
      }
      &__section {
        padding: 1.5rem 4.5rem;
        height: calc(100% - 6.7rem);
        overflow-y: scroll;
        text-align: justify;
      }
      &__footer {
        padding: 0.75rem 1.5rem;
        border-top: 1px solid $gray-16;
        box-shadow: 0px 1px 4px $gray-16;
      }
    }
  }
}

.check-icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0 !important;
}

.circle-check-icon {
  width: 1.5rem;
  height: 1.5625rem;
}